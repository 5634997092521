import "./styles.scss"

import React from "react"
import Slider from "react-slick"

import LazyFadeImage from "components/LazyFadeImage"

const Campaigns = () => {
  const slider = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 750,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    pauseOnHover: true,
  }

  const data = [
    require("assets/images/developer/developer-consulting-campaigns-image-1.png")
      .default,
    require("assets/images/developer/developer-consulting-campaigns-image-2.png")
      .default,
    require("assets/images/developer/developer-consulting-campaigns-image-3.png")
      .default,
    require("assets/images/developer/developer-consulting-campaigns-image-4.png")
      .default,
    require("assets/images/developer/developer-consulting-campaigns-image-5.png")
      .default,
  ]

  return (
    <section className="developer-consulting-campaigns">
      <div className="container-fluid">
        <h2>Przykład aktywnych kampanii marketingowych na projektach</h2>
        <p>
          Łącząc trendy w <strong>dziedzinie marketingu</strong> z
          doświadczeniem na <strong>rynku nieruchomości</strong> i wieloletnim
          funkcjonowaniem w <strong>obszarze sprzedaży</strong> zajmiemy się
          opracowaniem sprofilowanej{" "}
          <strong>strategii sprzedażowej i marketingowej</strong>.
        </p>

        <Slider {...slider} className="slick-slider--arrows-big">
          {data.map((item, index) => (
            <div key={index}>
              <LazyFadeImage>
                <img src={item} alt="" />
              </LazyFadeImage>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  )
}

export default Campaigns
