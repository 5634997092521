import "./styles.scss"

import React from "react"

import LazyFadeImage from "components/LazyFadeImage"

const Icons = () => {
  const data = [
    {
      icon: require("assets/images/developer/developer-consulting-icons-ico-1.svg")
        .default,
      header: "Google Ads i Facebook Ads",
    },
    {
      icon: require("assets/images/developer/developer-consulting-icons-ico-2.svg")
        .default,
      header: "Media społecznościowe",
    },
    {
      icon: require("assets/images/developer/developer-consulting-icons-ico-3.svg")
        .default,
      header: "organizacja dni otwartych na inwestycji",
    },
    {
      icon: require("assets/images/developer/developer-consulting-icons-ico-4.svg")
        .default,
      header: "promocja projektu na targach branżowych",
    },
    {
      icon: require("assets/images/developer/developer-consulting-icons-ico-5.svg")
        .default,
      header: "Zarządzanie stroną internetową projektu",
    },
    {
      icon: require("assets/images/developer/developer-consulting-icons-ico-6.svg")
        .default,
      header: "artykuły sponsorowane w lokalnej prasie",
    },
    {
      icon: require("assets/images/developer/developer-consulting-icons-ico-7.svg")
        .default,
      header: "Reklama wielkoformatowa",
    },
    {
      icon: require("assets/images/developer/developer-consulting-icons-ico-8.svg")
        .default,
      header: "ulotka reklamowa",
    },
    {
      icon: require("assets/images/developer/developer-consulting-icons-ico-9.svg")
        .default,
      header: "E-mail marketing",
    },
    {
      icon: require("assets/images/developer/developer-consulting-icons-ico-10.svg")
        .default,
      header: "Geolokalizacja",
    },
    {
      icon: require("assets/images/developer/developer-consulting-icons-ico-11.svg")
        .default,
      header: "Przedstawienie oferty szerokiej bazie klientów",
    },
    {
      icon: require("assets/images/developer/developer-consulting-icons-ico-12.svg")
        .default,
      header: "Współpraca z agencjami nieruchomości",
    },
  ]

  return (
    <section className="developer-consulting-icons">
      <div className="container-fluid">
        <h2>Indywidualne podejście</h2>
        <p>
          Każdy projekt jest inny, dlatego tak ważne jest zróżnicowanie
          podejmowanych działań związanych z{" "}
          <strong>promocją inwestycji</strong>. Do każdej kampanii podchodzimy
          indywidualnie. Działania marketingowe prowadzone są{" "}
          <strong>w formie tradycyjnej</strong> oraz{" "}
          <strong>e-marketingu</strong>. Szczególny nacisk kładziemy na{" "}
          <strong>rozwiązania online</strong> z zastosowaniem{" "}
          <strong>innowacyjnych rozwiązań</strong>. Nasze działania są ściśle{" "}
          <strong>powiązane z efektami sprzedażowymi</strong>.
        </p>
        <div className="row justify-content-center">
          {data.map((item, index) => (
            <div className="col-xl-2 col-lg-3 col-md-4 col-6" key={index}>
              <div className="developer-consulting-icons__item">
                <div className="developer-consulting-icons__item-icon">
                  <LazyFadeImage>
                    <img src={item.icon} alt="" />
                  </LazyFadeImage>
                </div>
                <h4>{item.header}</h4>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Icons
