import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Hero, Icons, Campaigns } from "page_components/developer-consulting"

const Developers = () => {
  const title =
    "Doradztwo oraz stworzenie strategii marketingowej i promocji projektu"
  return (
    <Layout
      seo={{
        title: title,
        description:
          "Każdy projekt jest inny, dlatego tak ważne jest zróżnicowanie podejmowanych działań związanych z promocją inwestycji. Do każdej kampanii podchodzimy indywidualnie. ",
      }}
    >
      <Breadcrumbs
        title={title}
        parent={{
          title: "Dla deweloperów",
          path: "/dla-deweloperow/",
        }}
      />
      <PageHeader title={title} />
      <Hero />
      <Icons />
      <Campaigns />
    </Layout>
  )
}

export default Developers
