import "./styles.scss"

import React from "react"

import LazyFadeImage from "components/LazyFadeImage"

const Hero = () => {
  return (
    <section className="developer-consulting-hero">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-xl-5 col-md-6">
            <h2>Wiemy jak dotrzeć do Twojej grupy Klientów</h2>
            <p>
              Opracowywanie koncepcji promocji Twojego projektu rozpoczniemy od{" "}
              <strong>dokładnego określenia grupy docelowej</strong>. Tutaj
              liczy się wszystko. Wiek, wykształcenie, stan rodzinny, a nawet
              to, jak wygląda typowy dzień naszego potencjalnego odbiorcy. Co go
              martwi, jakie ma cele? Odpowiedź na te pytania pozwoli nam
              określić narzędzia, którymi posłużymy się by do niego dotrzeć.
              Następnie:
            </p>
            <ul className="custom-list">
              <li>
                określimy <strong>przypuszczalne koszty</strong> działań
                marketingowych,
              </li>
              <li>
                będziemy <strong>nadzorować realizację kampanii</strong> zgodnie
                z założonym harmonogramem,
              </li>
              <li>
                będziemy <strong>monitorować, optymalizować i rozliczać</strong>{" "}
                działania.
              </li>
            </ul>
          </div>
          <div className="col-xl-6 offset-xl-1 col-md-6">
            <LazyFadeImage>
              <div
                className="developer-consulting-hero__image"
                style={{
                  backgroundImage: `url('${
                    require("assets/images/developer/developer-consulting-hero-image.jpg")
                      .default
                  }')`,
                }}
              />
            </LazyFadeImage>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
